var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : _c(
              "div",
              {
                style: {
                  visibility: _vm.maxHeight > 0 ? "visible" : "hidden",
                  overflow: "hidden",
                },
                attrs: { id: "body-content-area" },
              },
              [
                _c("div", { attrs: { id: "pagelayout" } }, [
                  _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "top-bar",
                          staticStyle: {
                            display: "inline-block",
                            width: "100%",
                            "margin-top": "-1rem",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { float: "left" },
                              attrs: { id: "dateRangeSelector" },
                            },
                            [
                              _c("label", [_vm._v("Selection Period:")]),
                              _vm._v("  "),
                              _vm.hideDateLabel
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": ".75em",
                                        color: "#808080",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "(" +
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              _vm.dates.currentDateRange.start
                                            )
                                          ) +
                                          " to " +
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              _vm.dates.currentDateRange.end
                                            )
                                          ) +
                                          ")"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("br"),
                              _c("kendo-dropdownlist", {
                                attrs: {
                                  id: "ddlReportDateRange",
                                  name: "ddlReportDateRange",
                                  "data-source": _vm.reportDateRanges,
                                  "data-text-field": "text",
                                  "data-value-field": "value",
                                },
                                on: { select: _vm.onReportDateRangeSelect },
                                model: {
                                  value: _vm.selectedReportDateRange,
                                  callback: function ($$v) {
                                    _vm.selectedReportDateRange = $$v
                                  },
                                  expression: "selectedReportDateRange",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticStyle: { float: "left" } }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.selectedReportDateRange === "3",
                                    expression:
                                      "selectedReportDateRange === '3'",
                                  },
                                ],
                                staticStyle: {
                                  "margin-left": ".5rem",
                                  "margin-bottom": "0px!important",
                                },
                                attrs: { id: "dateRangePicker" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "inline-flex" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-right": ".5rem",
                                        },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "reportStartDate" } },
                                          [_vm._v("Start")]
                                        ),
                                        _vm._v(" "),
                                        _c("br"),
                                        _c("kendo-datepicker", {
                                          staticStyle: { width: "200px" },
                                          attrs: {
                                            id: "reportStartDate",
                                            name: "reportStartDate",
                                            required: "required",
                                            min: _vm.dates.start.minDate,
                                            max: _vm.dates.start.maxDate,
                                            format: "yyyy-MM-dd",
                                          },
                                          on: {
                                            change: _vm.onStartDateRangeChange,
                                          },
                                          model: {
                                            value:
                                              _vm.dates.currentDateRange.start,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dates.currentDateRange,
                                                "start",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "dates.currentDateRange.start",
                                          },
                                        }),
                                        _c("br"),
                                        _c("span", {
                                          staticClass: "k-invalid-msg",
                                          attrs: {
                                            "data-for": "reportStartDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "reportEndDate" } },
                                          [_vm._v("End")]
                                        ),
                                        _c("br"),
                                        _c("kendo-datepicker", {
                                          staticStyle: { width: "200px" },
                                          attrs: {
                                            id: "reportEndDate",
                                            name: "reportEndDate",
                                            required: "required",
                                            max: _vm.dates.end.maxDate,
                                            format: "yyyy-MM-dd",
                                          },
                                          on: {
                                            change: _vm.onEndDateRangeChange,
                                          },
                                          model: {
                                            value:
                                              _vm.dates.currentDateRange.end,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dates.currentDateRange,
                                                "end",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "dates.currentDateRange.end",
                                          },
                                        }),
                                        _c("br"),
                                        _c("span", {
                                          staticClass: "k-invalid-msg",
                                          attrs: {
                                            "data-for": "reportEndDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                float: "right",
                                "margin-top": ".9rem",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "button is-accent",
                                  attrs: {
                                    disabled: !_vm.$hasPermissions(
                                      _vm.clientSession,
                                      ["ACTIVITY_REPORT"],
                                      2
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      !_vm.$hasPermissions(
                                        _vm.clientSession,
                                        ["ACTIVITY_REPORT"],
                                        2
                                      )
                                        ? null
                                        : _vm.getCsvExport()
                                    },
                                  },
                                },
                                [
                                  _vm._m(0),
                                  _c("span", [_vm._v(" Export Log ")]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                float: "right",
                                "margin-top": ".9rem",
                                "margin-right": ".5rem",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "button is-light",
                                  on: { click: _vm.clearFilters },
                                },
                                [_c("span", [_vm._v("Clear Filters")])]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("Grid", {
                        style: {
                          height: _vm.maxHeight + "px",
                          maxHeight: _vm.maxHeight + "px",
                        },
                        attrs: {
                          filter: _vm.filter,
                          "data-items": _vm.activity,
                          sortable: true,
                          sort: _vm.sort,
                          filterable: true,
                          pageable: Object.assign({}, _vm.pageable, {
                            pageSizes: _vm.pageSizes,
                          }),
                          "page-size": _vm.pageSize,
                          skip: _vm.skip,
                          take: _vm.take,
                          total: _vm.totalRecords,
                          columns: _vm.columns,
                        },
                        on: {
                          headerselectionchange: _vm.onHeaderSelectionChange,
                          filterchange: _vm.filterChangeHandler,
                          sortchange: _vm.sortChangeHandler,
                          pagechange: _vm.pageChangeHandler,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "dateTemplate",
                            fn: function (ref) {
                              var props = ref.props
                              return [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      color: "#424242 !important",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getLocalDate(
                                            props.dataItem.createdAt
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "emailTemplate",
                            fn: function (ref) {
                              var props = ref.props
                              return [
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getEmailIfExists(
                                          props.dataItem.userId
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "filterSlotTemplate",
                            fn: function (ref) {
                              var props = ref.props
                              var methods = ref.methods
                              return [
                                _c("div", { staticClass: "k-filtercell" }, [
                                  _c(
                                    "div",
                                    { staticClass: "k-filtercell-wrapper" },
                                    [
                                      _c("input", {
                                        staticClass: "k-textbox",
                                        attrs: {
                                          type: "text",
                                          id: "" + props.field,
                                        },
                                        domProps: { value: props.value },
                                        on: {
                                          input: function (ev) {
                                            methods.change({
                                              operator: "contains",
                                              field: props.field,
                                              value: ev.target.value,
                                              syntheticEvent: ev,
                                            })
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
        _vm.maxHeight === 0 ? _c("div", [_c("Loading")], 1) : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-file-csv" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }